import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import CrowdfundingFulfillment from 'images/blog-images/indiegogo-crowdfunding.png';
import Indiegogo from 'images/blog-images/indiegogo-site.png';
import IndiegogoFeatured from 'images/featured-image/indiegogo-featured.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query IndiegogoShipping {
      file(relativePath: { eq: "indiegogo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="indiegogo shipping & fulfillment solutions powered by shipkoo"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Indiegogo Shipping & Fulfillment Solutions Powered By Shipkoo | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-indiegogo-shipping" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Indiegogo Shipping & Fulfillment Solutions Powered By Shipkoo | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-indiegogo-shipping" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: IndiegogoFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="indiegogo-shipping-fulfillment-solutions-powered-by-shipkoo"
        title="Indiegogo Shipping & Fulfillment Solutions Powered By Shipkoo"
        date="2021-02-15"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Crowdfunding is the collective effort of a large number of individuals who network and pool small amounts of capital to finance a new or existing business. There are two factors that make ours the age of crowdfunding. First is the power of numbers. The second is the power of ideas. The global crowdfunding market size was $84 billion as of 2018 and is expected to reach $114 billion by 2021 according to <a href="https://www.smallbizgenius.net/by-the-numbers/crowdfunding-stats/#gref" target="_blank" rel="noreferrer">smallbizgenius</a>.
            </p>

            <p>
              After successfully launching a crowdfunding campaign and the production of your product is finalized, what is the next? Getting your products to your backers as soon as possible. For Indiegogo users, you need an Indiegogo fulfillment that works well with your products specification to meet your crowdfunding backers’ promise. With Shipkoo you are at the right address for completing this final and crucial step.
            </p>

            <Image src={Indiegogo} className="" alt="indiegogo shipping and fulfillment"/>

            <h5>
              <strong>
                What is Indiegogo?
              </strong>
            </h5>

            <p>
              Founded in 2008, Indiegogo has operations in more than 235 countries and has helped fund more than 800,000 creative ideas. It's a clear pick as best for going to market because it offers partnerships to help with all aspects of going live and launching. This includes creative services, communications, production, retail, and distribution partnerships.
            </p>

            <p>
              Unlike most crowdfunding platforms that only allow you to collect the amount of money you've raised once you meet a certain goal, with Indiegogo you have options. Indiegogo requires a fundraising minimum of $500 to run a crowdfunding campaign and you can run a campaign for a maximum of 60 days, regardless of the type of funding option you choose. If you meet your goal, Indiegogo will send you a payout within 15 business days.
            </p>

            <p>
              When you plan to launch an Indiegogo crowdfunding campaign. First of all, you set up an Indiegogo campaign page, tell your story, and introduce the project to your potential backers. The aim is to raise enough to fund the idea or project.
            </p>

            <p className="no-bottom">
              Some of the categories where major crowdfunding campaigns have succeed include:
            </p>

            <UnorderedList>
              <li>
                Tech and innovationHome
              </li>
              <li>
                Healthcare and fitness
              </li>
              <li>
                Energy and green tech
              </li>
              <li>
                Community rights
              </li>
              <li>
                Transportation
              </li>
              <li>
                Healthcare and fitness
              </li>
            </UnorderedList>

            <h5>
              <strong>
                Indiegogo crowdfunding fulfillment
              </strong>
            </h5>

            <p>
              After a successful Indiegogo campaign, you need to fulfill your promise to your backers by shipping the product to them. Shipping a crowdfunded product to your backers can cost between 10-20% of the funds. Our goal is to completely release you of the burden of figuring out how to get your products to your backers. The following are some key factors to consider:
            </p>

            <p>
              <strong>Product packaging</strong>
            </p>

            <p>
              The art of packaging could not only effectively save space, thus reducing shipping costs, but also secure the shipment all the way. Elaborated packaging helps you to save a lot of operating costs. What happens in shipping packages is that they are charged according to actual weight, often size and volume will be taken into account too, which is called the package’s dimensional weight.
            </p>

            <p>
              For example, if you take 10 cm off the outer box packaging, reducing the “length+width+height” from 100cm to 90cm. It saved around $15 per package. With 1,000 orders to be shipped, this is gonna save $15,000 in total.
            </p>

            <p>
              <strong>Estimation of shipping destinations & Target markets</strong>
            </p>

            <p>
              You need to know where do you want the majority of your backers to come from? Keep in mind that most backers come from the U.S, Canada, the United Kingdom and Australia.
            </p>

            <p>
              Shipping can become complicated when your backers are located around the world. The delivery time differs in regions. Make research on similar projects to estimate the regional distribution of the backers and based on the statistics, calculate the shipping costs and shipping time you need to get all the backers to receive the rewards at the same time. Extra fees and time would apply when shipping to remote areas.
            </p>

            <p>
              <strong>Duties and taxes</strong>
            </p>

            <p>
              This depends on the products because each country has its own tax rules that customers who buy the product into that country must adhere to.
            </p>

            <p>
              You need to know if additional charges are charged as tax and duties levied to customers who buy your product. However, you do not need to worry about that,Shipkoo will help with this. Shipkoo structures your parcel shipping process to minimize the cost of duties and avoid duties on shipments to the US under $800 in value.
            </p>

            <Image src={CrowdfundingFulfillment} alt="shipkoo crowdfunding fulfillment" />

            <h5>
              <strong>
                Your trustful Indiegogo shipping and fulfillment partner
              </strong>
            </h5>

            <p>
              Shipkoo specializes in <Link to="/services/crowdfunding-fulfillment/">crowdfunding campaign fulfillment</Link>. Our proprietary features designed to manage your crowdfunding fulfillment. No matter where your backers live, Shipkoo crowdfunding pick, pack & dispatch services can get your rewards to your supporters from Indiegogo. Our cutting-edge order management platform enables you to import your orders easily and get full visibility on the status of your orders. Based on your needs and desires regarding costs and speed, we can provide you a variety of options including sea, air and railway services. This gives you complete control over your shipping costs and your total operating costs.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;